@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/Poppins-Regular.ttf') format('truetype'),
    url('../public/assets/fonts/Poppins-Regular.woff') format('woff'),
    url('../public/assets/fonts/Poppins-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-strong';
  src: url('../public/assets/fonts/strong/Poppins-Black.ttf') format('truetype'),
    url('../public/assets/fonts/strong/Poppins-Black.woff') format('woff'),
    url('../public/assets/fonts/strong/Poppins-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'cursiva';
  src: url('../public/assets/fonts/cursiva/cursiva.ttf') format('truetype'),
    url('../public/assets/fonts/cursiva/cursiva.woff') format('woff'),
    url('../public/assets/fonts/cursiva/cursiva.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* los estilos esan ordenados por orden de acomodo en la pagina web */

/* media querys  */
.mobile {
  @media (min-width:320px) {
    display: none;
  }
}

.pc {
  @media (max-width:320px) {
    display: none;
  }
}

/* celulares ish */
@media (max-width:550px) {
  .navbar-container {
    display: flex;
    width: 100%;
    background-color: #020202cc;
    justify-content: space-around;
    align-content: center;
    align-items: center;
  }

  .navbar {
    background-color: transparent !important;
  }




  .mobile-menu-links {
    width: fit-content;
    height: fit-content;
    z-index: 19000;
    position: absolute;
    background-color: #020202cc;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    top: 4.57rem;
    transform: translateX(-50%);
  }

  a.mobile-menu-item {
    color: white;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin: 1rem;
    text-transform: uppercase;
    width: max-content;
  }

  .navbar {
    justify-content: flex-start !important;
  }

  .mobile-menu-button {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 10%;
    align-items: center;
    z-index: 25000;
    justify-content: flex-end;
    margin-bottom: 0.7rem;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #c9c9c9;
    /* color de las barras */
    margin: .15rem 0;
    transition: 0.4s;
  }

  /* Estilos para animar el botón cuando se hace clic */
  .mobile-menu-button.clicked .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .mobile-menu-button.clicked .bar:nth-child(2) {
    opacity: 0;
  }

  .mobile-menu-button.clicked .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .link-wrapper {
    display: none;
  }

  .tech-tittle-reverse {
    font-size: 1.8rem !important;
  }

  .tech-tittle {
    font-size: 1.8rem !important;
  }

  .head_style_2 {
    font-size: 1.5rem !important;
  }

  .text-divider {
    width: 70vw !important;
  }

  .wrapper {
    transform: translate(10%, 50%) !important;
  }

  .card img {
    filter: opacity(100%) !important;
  }

  .video-tech {
    border-radius: 1.56rem !important;
  }

  .techtext-title {
    font-size: 1.3rem !important;
  }

  .subtitle-banner-text {
    font-size: 1.5rem !important;
  }

  .list_style {
    padding: 1rem !important;
  }

  .list_style li {
    min-width: 100% !important;
  }

  .video-flex {
    padding: 1rem !important;
  }

  .project-video {
    width: 100% !important;
  }

  .title_section {
    margin: 1.5rem;
  }

  .video-moti-way {
    width: 70%;
    margin: 1rem;
  }

  .values-main {
    margin: 4rem 1rem !important;
  }

  .main-title-container {
    text-align: center;
  }

  .logo img {
    width: 4.5rem !important;
  }

}

/* .slick-slide {
  width: 100% !important;
}
 */
/* laptop  */
@media (max-width:1374px) {
  .navbar-item {
    font-size: 0.8rem !important;
  }

  .subtitle-banner-text {
    font-size: 1.5rem;
  }

  .title-values {
    font-size: 1.5rem !important;
  }

  .philos-container {
    width: fit-content !important;
  }

  .card img {
    width: 100%;
  }

  .counter-list {
    align-content: space-around !important;
    justify-content: center !important;
  }

  .counter-list li {
    margin: 1rem !important;
  }

  .text-video {
    padding: 1rem !important;
  }

  .text-video-reverse {
    padding: 1rem !important;
  }
}

/* solo tablet  */

@media (min-width: 950px) and (max-width: 1374px) {
  .subtitle-banner-text {
    font-size: 2.5rem !important;
  }

}

@media (min-width: 551px) and (max-width: 950px) {
  .text-divider {
    width: 25rem !important;
  }

  .techtext-title {
    font-size: 2rem !important;
  }

  .list_style li {
    min-width: 40% !important;
  }

}



/* tablet y mas */

@media (max-width:950px) {
  .video-flex {
    flex-direction: column !important;
  }


  .textbanner-title {
    font-size: 1.5rem !important;
    padding: 1.5rem !important;
  }

  .textbanner-text {
    font-size: 1.3rem !important;
  }

  .product-title {
    font-size: 2.9rem !important;
  }



  /* .card {
    
    border-radius: 1rem;
  } */

  .techtext-title {
    margin: 2rem !important;
  }

  .list_style li {
    margin: 1rem !important;
    font-size: 1.2rem !important;
  }

  .list_style {
    justify-content: center !important;
  }

  .text-video {
    text-align: center !important;
  }

  .text-video-reverse {
    text-align: center !important;
  }

  .video-box {
    width: 100% !important;
  }

  .ecommerce-icons {
    padding: 0rem !important;
  }

  .values-wrap {
    flex-wrap: wrap;
  }

  .philos-container {
    width: 100% !important;
  }

  .navbar-item {
    font-size: .7rem !important;
    padding: .5rem !important;
  }

  .title-banner-text {
    font-size: 1.5rem !important;
  }


  .video-banner {
    min-height: auto !important;
  }

  .contact-section {
    text-align: center;
    padding: 2rem;
    background-color: #fff;
    margin: 6rem 0rem;
    max-width: 80vw !important;
  }

  .testimonial.inactive {
    display: none;
  }

}


/* terminan media querys  */

/* variables globales */
/* .section {
  min-height: 100vh;
} */

.video-tech {
  width: 100%;
}

/*Terminan variables globales */

/* Navbar */
.navbar-container {
  top: 0;
  z-index: 1000;
  position: sticky;
}

.navbar {
  background-color: #000000;
  color: #fff;
  display: flex;
  text-align: center;
  z-index: 100;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  min-height: 90px;
}

.navbar-item {
  color: white;
  text-decoration: none;
  padding: 1rem;
  font-family: Nunito sans;
  font-size: 1rem;
  margin: auto;
  text-transform: uppercase;
}

.navbar-item:hover {
  color: #f1f1f1;
  background-color: #88cfc7;
  border-radius: 2rem;
  transition: 0.3s linear;
}

.link-wraper {
  display: flex;

}

.logo img {
  width: 5rem;
  padding: .5rem;
}

/*Termina Navbar*/


/* empieza texto en el video banner  */


/* Estilo para escritorio y tablet */
@media (min-width: 768px) {
  .slider-image.mobile {
    display: none;
  }
}

/* Estilo para móvil */
@media (max-width: 767px) {
  .slider-image.pc {
    display: none;
  }
}

.slick-dots {
  position: initial !important;
}

/* termina texto en el video banner  */


/* empieza infobanner  */
.textbanner-container {
  background: white;
  position: relative;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.textbanner-title {
  font-size: 2rem;
  font-weight: bold;
  color: #313332;
  padding: 2.5rem;
}

.textbanner-text {
  font-size: 1.8rem;
  color: #908f8f;
  padding-bottom: .5rem;
}

.textbanner-footer {
  font-size: 1.2rem;
  font-weight: bold;
  color: #313332;
}

.textbanner-footer {
  padding-bottom: 1.5rem;
}

/* termina infobanner  */

/* empieza countbanner */
@media (max-width: 768px) {
  .counter-list li:nth-child(1) {
    width: 200px !important;
    height: 200px !important;
  }

  .counter-list li:nth-child(2) {
    width: 200px !important;
    height: 200px !important;
    top: 0px !important;
  }

  .counter-list li:nth-child(3) {
    width: 200px !important;
    height: 200px !important;
    top: 0px !important;
  }

  .counter-list li:nth-child(4) {
    width: 200px !important;
    height: 200px !important;
    top: 0px !important;
  }

  .counter-list li:nth-child(5) {
    top: 0px !important;
  }

  .counter-list li:nth-child(6) {
    width: 200px !important;
    height: 200px !important;
    top: 0px !important;
  }

  span.main-count-title {
    font-style: italic;
    font-size: 2.2rem !important;
  }

  span.highligth-count-title {
    font-size: 2.2rem !important;
  }

}

span.highligth-count-title {
  font-size: 3.2rem;
  background-image: url('/public/assets/img/home-page/high.png');
  background-size: 99%;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
}

.banner img {
  width: 100%;
}

span.main-count-title {
  font-style: italic;
  font-size: 3.2rem;
}

span.soft-text {
  text-transform: uppercase;
  font-size: 1.3rem;
}

.text-counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.main-count-cointainer {

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.counter-list {
  padding: 0;
  margin: 70px 0;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  position: relative;
  min-height: 40vh;
}

/* Individual counter items */
.counter-list li {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 1.2;
}

/* Specific sizes and gradient backgrounds for each circle */
.counter-list li:nth-child(1) {
  background: radial-gradient(circle, rgba(145, 3, 26, 1) 0%, rgb(255, 255, 255) 100%);
  width: 150px;
  height: 150px;
  font-size: 1.2rem;
}


.counter-list li:nth-child(2) {
  background: radial-gradient(circle, rgba(14, 126, 121, 1) 0%, rgba(255, 255, 255, 0.2) 100%);
  width: 230px;
  height: 230px;
  font-size: 2rem;
  top: 80px;
}

.counter-list li:nth-child(3) {
  background: radial-gradient(circle, rgba(90, 17, 60, 1) 0%, rgba(255, 255, 255, 0.2) 100%);
  width: 120px;
  height: 120px;
  font-size: 1.5rem;
  top: 20px;
}



.counter-list li:nth-child(4) {
  background: radial-gradient(circle, rgba(158, 9, 24, 1) 0%, rgba(255, 255, 255, 0.2) 100%);
  width: 130px;
  height: 130px;
  font-size: 1.1rem;
  top: 200px;
}

.counter-list li:nth-child(5) {
  background: radial-gradient(circle, rgba(241, 119, 41, 1) 0%, rgba(255, 255, 255, 0.2) 100%);
  width: 200px;
  height: 200px;
  font-size: 1.8rem;
  top: 30px;
}

.counter-list li:nth-child(6) {
  background: radial-gradient(circle, rgba(64, 204, 191, 1) 0%, rgba(255, 255, 255, 0.2) 100%);
  width: 140px;
  height: 140px;
  font-size: 1.3rem;
  top: 210px;
}

/* Number and text styles */
.show-count {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.text-count {
  font-size: 1rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
}

/* Optional: hover effect to emphasize items */
.counter-list li:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

/* Ensuring circles overlap properly on smaller screens */
@media (max-width: 768px) {
  .counter-list {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .counter-list li {
    position: relative;
    top: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .counter-list {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .counter-list li {
    position: relative;
    top: 0;
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .counter-list {
    max-width: 100%;
  }

  .counter-list li:nth-child(1) {
    width: 110px !important;
    height: 100px !important;
    font-size: 0.2rem !important;
  }

  .counter-list li:nth-child(2) {
    width: 180px !important;
    height: 170px !important;
  }

  .counter-list li:nth-child(3) {
    width: 140px !important;
    height: 130px !important;
  }

  .counter-list li:nth-child(5) {
    width: 160px !important;
    height: 150px !important;
  }

  .counter-list li:nth-child(6) {
    width: 110px !important;
    height: 100px !important;
  }

  .text-count {
    font-size: 0.7rem !important;
  }

  .show-count {
    font-size: 1.5rem;
  }

  li.count {
    font-size: 1rem !important;
  }
}

/* termina countbanner */


/* empieza productos y servicios */
.product-container {
  text-align: center;
  padding: 2rem;
}

.product-title span {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
}

.product-text {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: #666;
}

.product-links {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  /* Permite que las tarjetas se ajusten en varias filas en pantallas más pequeñas */
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 8.5rem;
  height: 22rem;
  border: 0.2rem solid #333;
  border-radius: 7rem;
  background-color: #fff;
  transition: transform 0.3s ease-in;
}

.card:hover {
  transform: scale(1.05);
}


.card-image {
  width: 110%;
  height: auto;
  margin-bottom: 1rem;
}

.product-card-title {
  writing-mode: vertical-rl;
  font-weight: bold;
  font-size: 1.1rem;
  color: #333;
  margin-top: 1rem;
  transform: rotate(180deg);
  white-space: pre-wrap;
  text-transform: uppercase;
  text-align: end;
}

.card-link {
  text-decoration: none;
}


/* Estilos para pantallas más pequeñas */


@media (max-width: 767px) {
  .contact-input {
    width: 70%;
  }

  .card-image {
    margin-left: -1rem;
  }

  .card img {
    max-width: 18rem;
    width: 45%;
  }

  .card {
    width: 95.6% !important;
    margin: auto;
    flex-direction: unset;
    height: 7rem;
    gap: 1rem;
    justify-content: flex-start;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.6s ease, transform 0.6s ease;
  }

  .product-card-title {
    transform: none;
    writing-mode: initial;
    font-size: 0.9rem;
    text-align: center;
  }

  /* Animación de Fade-In */
  .fade-in {
    opacity: 1 !important;
    transform: translateY(0);
    border: 2px solid var(--card-hover-color);
    border-radius: 4rem;
    transition: opacity 0.8s ease, transform 1s ease, border-color 2s ease !important;
  }

  /* Animación de Fade-Out */
  .fade-out {
    opacity: 0 !important;
    transform: translateY(20px);
  }

  .product-links {
    gap: 4rem !important;
  }
}



@media (hover: hover) and (pointer: fine) {
  .card:hover::before {
    height: 100%;
    opacity: 100%;
    background-color: var(--card-hover-color);
  }

  .card:hover .card-higligth {
    color: white !important;
  }

  .card:hover .product-card-title {
    color: white !important;
  }
}

.card::before {
  opacity: 0%;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  transition: background 0.5s ease, opacity 0.3s ease, height 0.6s ease-out;
  border-radius: 7rem;
  z-index: -1;
}



/* termina productos y servicios */

/* empieza tech */
.techtext-container {
  text-align: center;
}

.techtext-text {
  font-size: 2.6rem;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #313332;
  margin: 2rem 0rem;
}

.techtext-title {
  font-size: 2.4rem;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-align: center;
  margin: 1rem 0rem;
}

.list_style li {
  line-height: 36px;
  margin: 0.5rem 0rem;
  width: 33%;
  font-size: 1.6rem;
  color: gray;
  font-family: 'Poppins', sans-serif;
}

.list_style {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  list-style: none;
  text-align: center;
  flex-wrap: wrap;
  padding: 0rem 1rem;
}

.head_style_2 {
  font-size: 3.5rem;
  color: #313332;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.animation_wrap {
  text-align: center;
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.video-flex {
  display: flex;
  justify-content: space-evenly;
  background-color: #ecedec;
  padding: 5rem;
}

.flex-row {
  background: #757575;
}

.video-box {
  width: 100%;
  margin: auto;
}

.flex-row .tech-title {
  color: white;
}

.text-video-reverse {
  color: rgb(71, 71, 71);
  padding: 3rem;
}

.text-video {
  color: rgb(255, 255, 255);
  padding: 3rem;
}

.tech-tittle-reverse {
  font-size: 2.8rem;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

.tech-tittle {
  font-size: 2.8rem;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

.title_section {
  margin-bottom: 2.5rem;
  text-align: center;
}

.parrafo {
  font-size: 1.3rem;
  text-align: justify;
}

.video-tech {
  margin: auto;
}

/* termina tech */

/* empieza ecommerce */
@media only screen and (max-width: 768px) {

  .big-tittle-ecommerce {
    font-size: 2rem !important;
  }

  .small-tittle-ecommerce {
    font-size: 0.8rem !important;
  }

  .description-ecommerce {
    font-size: 0.8rem !important;
  }

  .icon-commerce {
    width: 100px !important;
  }

  .ecommerce-icons {
    flex-wrap: nowrap !important;
    justify-content: center !important;
    margin-top: 2rem;
  }

  .link-icon {
    margin: 5px 5px !important;
  }

  .text-divider-ecommerce {
    width: 80% !important;
    margin: auto;
  }

  .banner-container {
    border-radius: 1rem !important;
    margin: 0.5rem !important;
  }

  .description-ecommerce {
    gap: 0 !important;
  }
}





.big-tittle-ecommerce {
  font-size: 3rem;
  font-weight: bold;

}

img.dots-moti {
  height: 2rem;
  width: 8rem;
  margin: auto;
  margin-top: 1rem;
}

.small-tittle-ecommerce {
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  color: lightgray;
}

.description-ecommerce {
  font-size: 1.2rem;
  display: flex;
  text-transform: uppercase;
  text-align: center;
  gap: 5rem;
  justify-content: space-between;
  margin: 2rem;
  font-style: italic;
  color: lightgray;
}

.tittle-ecommerce {
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.banner-container {
  background-color: #000;
  /* Fondo oscuro */
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-divider-us {
  background-color: #656766;
  height: 3px;
  width: 100%;
  border-radius: 10px;
}

.ecommerce-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
}

.text-divider-ecommerce {
  background: white;
  height: 4px;
  border-radius: 2px;
}

.icon-commerce {
  width: 200px;
  height: auto;
  filter: brightness(0) invert(1);
  /* Hace que los íconos sean blancos sobre fondo oscuro */
  transition: transform 0.5s ease;
}

.icon-commerce:hover {
  transform: scale(1.1);
  /* Efecto de zoom al pasar el mouse */
}

.link-icon {
  margin: 20px 20px;
}

/* termina ecommerce */

/* empieza us */

/* VARIABLES */
:root {
  --white: #FFF;
  --gray: #444;
  --blue: #21ACE7;
  --yellow: #FFC26B;
  --black-3: rgba(0, 0, 0, .3);
  --font-size-lg: 18px;
}

/* MIXINS */
/* Animación mixin */
@keyframes rotate90deg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

/* Mixin para animaciones */
.animate {
  transition: 0.4s;
  animation-timing-function: linear;
  transform-style: preserve-3d;
}

/* Estilos generales */

/* Input checkbox */
input[type=checkbox] {
  display: none;
}

/* Checkbox en estado seleccionado */
input[type=checkbox]:checked {
  ~.collapse {
    transition: height 0.4s ease, opacity 0.4s ease;
    height: 100%;
  }

  ~.collapse .iconPlus {
    animation: rotate90deg 0.4s linear;
  }

  ~.collapse .minus {
    animation: rotate90deg 0.4s linear;
  }

  ~.collapse ul {
    animation: fadeIn 0.4s linear;
  }
}

/* Elemento de colapso */
.collapse {
  transition: height 0.4s ease, opacity 0.4s ease;
  display: block;
  height: 2rem;
  margin: 1rem;
  padding: 10px 15px;
  overflow: hidden;
  cursor: pointer;
  background: var(--white);
  text-align: left;
}

.collapse .iconPlus {
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  width: 15px;
  border: solid 1px gray;
  border-radius: 12px;
  padding: 0.098rem;
}

.collapse .iconPlus .plus {
  display: block;
  width: 2px;
  height: 15px;
  margin: 0 6.5px;
  background: gray;
}

.collapse .iconPlus .minus {
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 2px;
  top: 8px;
  background: gray;
}

.collapse .details {
  display: block;
  padding: 15px 0;
  color: gray;
  font-size: 15px;
}

.values-container {
  width: 100%;
}

.philos-container {
  width: 50%;
}

.title-values {
  font-size: 1.4rem;
  color: #5c5c5c;
  margin: 0rem 0.5rem;
}

/* Termina elemento de colapso */

.main-title-container {
  text-align: left;
}

.us-title {
  font-size: 2rem;
  font-weight: bold;
  color: #313332;
  padding: 2.5rem 0rem;
}

.us-text {
  font-size: 1.8rem;
  color: #908f8f;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-around;
}

.values-wrap {
  display: flex;
}

.icon-values {
  height: 2.2rem;
  padding: 0rem .5rem;
}

.values-main {
  margin: 4rem 10rem;
}

.values-div {
  min-width: 50%
}

/* Termina seccion Us */
/* empieza Mailer */



.margin-6 {

  padding: 1.5rem !important;
  display: block;

}


/* empieza footer */
.footer {
  background-color: #313332;
  color: #fff;
  display: flex;
  justify-content: space-around;
  /* width: 100vw; */
  flex-wrap: wrap;
  align-content: space-around;
}

.footer-column {
  margin: 1rem;
}

.footer-title {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
}

.footer-list {
  list-style: none;
  padding-inline-start: 0;
}

.footer-list-item {
  padding: .5rem;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
}

.footer-title-divider {
  height: 2px;
  background-color: #FFF;
  width: 100%;
  margin: auto;
  margin-left: 1rem;
}

.title-wrap {
  display: flex;
  justify-content: flex-start;
}

.img-tablet {
  width: 39rem;
  position: absolute;
}

/* termina footer  */

/* empieza visual merchandising  */
.slick-prev,
.slick-next {
  background-color: #ffffff !important;
  border-radius: 50% !important;
  width: 4rem !important;
  height: 4rem !important;
  text-align: center !important;
  z-index: 9;
}

/* Estilos al pasar el ratón sobre las flechas */
.slick-prev:hover,
.slick-next:hover {
  background-color: #f1f1f1 !important;
}



/* Establece el ancho de las diapositivas al 100% del contenedor del slider */
.slick-slide {
  width: 100%;
}

.slick-next:before {
  content: '>' !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 2rem !important;
  margin: auto !important;
  color: #90d0c3 !important;
}

.slick-prev:before {
  content: '<' !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 2rem !important;
  margin: auto !important;
  color: #90d0c3 !important;
}



/* .slick-slide:hover img {

  filter: brightness(1);
}
 */

.projects-container {
  display: flex;
}

.projects-container {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0 20vw;
}

.project-video {
  width: 50%;
  align-content: center;
  text-align: center;
}

h3.projects-tittle {
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: 100;
  color: #313332;
}

.poject-description {
  width: 90%;
  margin: auto;
  color: #313332;
}

.container.project-video {
  text-align: center;
  margin: auto;
}

h4.creation-subtitle {
  color: #908f8f;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0;
}

.div-back {
  text-align: center;
  margin: 2rem;
}

.img-back {
  width: 9rem;
}

.footer-column:last-child .footer-list {
  display: flex;
}

.local-image {
  width: 2rem;
}

.footer-list-item a {
  text-decoration: none;
  color: white;
}




/* mpieza mailer  */
.contact-section {
  text-align: center;
  padding: 2rem;
  background-color: #fff;
  margin: 6rem 0rem;
  max-width: 60vw;
}

.contact-title {
  font-size: 3rem;
  color: #000;
  margin-bottom: 1rem;
  font-family: 'Poppins-strong';
}

.contact-highlight {
  font-size: 3rem;
  color: #000;
  display: block;
  font-family: 'Poppins';
  font-weight: normal;
}

img.dots-mailer {
  max-width: 19%;
}

.contact-description {
  color: rgb(94, 30, 63);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  max-width: 75%;
  margin: auto;
}

.contact-subtext {
  color: #000000;
  font-size: 1.3rem;
  font-style: italic;
  margin-bottom: 1.5rem;
}

.contact-form {
  display: flex;
  justify-content: center;
}

.contact-input {
  padding: 1rem;
  border: 1px solid #f1c8c8;
  background-color: #fce9e9;
  font-size: 1rem;
  text-align: center;
  border-radius: 9px 0px 0px 9px;
  font-family: 'Poppins', sans-serif !important;
  margin: 0.4rem 0rem;
}

.contact-button {
  background-color: #d82428;
  color: #fff;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 9px;
  font-family: 'Poppins', sans-serif !important;
}

.contact-button:hover {
  background-color: #c41f1f;
}

.main-mailer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem;
}

@media (min-width: 1100px) and (max-width: 1500px) {
  .contact-title {
    font-size: 2.3rem !important;
  }

  .contact-highlight {
    font-size: 2.3rem !important;
  }

  .contact-description {
    font-size: 1.1rem !important;
  }

  img.dots-mailer {
    max-width: 15% !important;
  }

  .contact-subtext {
    font-size: 1rem !important;
  }
}


@media (min-width: 768px) and (max-width: 1099px) {
  .contact-title {
    font-size: 2.1rem !important;
  }

  .contact-highlight {
    font-size: 2.1rem !important;
  }

  .contact-description {
    font-size: 1rem !important;
  }

  img.dots-mailer {
    max-width: 15% !important;
  }

  .contact-subtext {
    font-size: 1rem !important;
  }
}

@media (max-width: 767px) {

  .contact-title {
    font-size: 1.8rem !important;
  }

  .contact-highlight {
    font-size: 1.8rem !important;
  }

  .contact-description {
    font-size: 1rem !important;
  }

  img.dots-mailer {
    max-width: 30% !important;
  }

  .contact-subtext {
    font-size: 1rem !important;
  }


}


/* empieza marquee */

.marquee-wrapper {
  background-color: #90d0c3;
  /* Color similar al de la imagen */
  padding: 1rem 0;
  overflow: hidden;
  /* Importante para ocultar el contenido que sale del contenedor */
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee-content {
  display: inline-block;
  animation: marquee 15s linear infinite;
  font-size: 1.2rem;
  color: white;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
    /* Se mueve el 50% ya que se repite el contenido */
  }
}


/* Empieza banner app  */

.img-app {

  max-width: 100%;
}

.banner-app-container {
  padding: 3rem 0rem;
}


.pc {
  display: block;
}

.mobile {
  display: none;
}

/* Pantalla móvil */
@media (max-width: 768px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }
}

/* empieza slider testimonieales  */
.testimonial-slider {
  display: flex;
  align-items: stretch;
  /* Estira todos los elementos hijos a la misma altura */
  justify-content: center;
  position: relative;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #e9e9e9;
  border-radius: 10px;
  overflow: hidden;
}

.testimonial {
  flex: 1;
  /* Cada testimonio ocupará el mismo espacio */
  text-align: center;
  padding: 20px;
  margin: 0 10px;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: scale(0.9);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial.active {
  opacity: 1;
  transform: scale(1);
}


.quote {
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
}

.author-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.author-info img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: solid 1px gray;
}

.author {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.role {
  font-size: 0.875rem;
  color: #888;
}

.arrow {
  background: none;
  border: none;
  font-size: 2rem;
  color: #ccc;
  cursor: pointer;
  padding: 0 10px;
}

.arrow:hover {
  color: #333;
}

.dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  width: 100%;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #333;
}

/* Alineamos los testimonios */
.testimonial-slider .testimonial {
  margin: 0 10px;
}

.testimonial-slider .testimonial.active {
  margin: 0 20px;
  /* Un poco más de espacio para el activo */
}

.testimonial-title {
  text-align: center;
  font-size: 2rem;
}

.main-testimonial {
  margin: 2rem 0rem;
}

.testimonial {
  background: #ffffff;
  border-radius: 23px;
}

img.quote-icon {
  max-width: 4rem;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.highlight {
  position: relative;
  font-weight: bold;
  color: black;
  /* Ajusta el color del texto */
}

.highlight::after {
  content: '';
  position: absolute;
  top: 11px;
  right: 2px;
  width: 9.5px;
  height: 9.5px;
  background-color: red;
  border-radius: 50%;
}


.testimonial-title>span {
  color: darkgray;
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
}

.moti-testimonial {
  margin-top: 0px !important;
}


/* banner valores */

.value-banner {
  max-height: 60vh;
}

.value-container {
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.value-banner-mobile {
  max-width: 100%;
}


/* empieza trusted  */

.main-container-trusted {
  background: url('../public/assets/img/home-page/trusted/fondo-trusted.png');
  background-size: cover;
  padding: 2rem;
  margin-top: 4rem;
}

.tittle-trusted {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2.3rem;
  margin: 1rem 0;
}

span.tittle-bold-trusted {
  font-weight: bolder;
}

img.icon-trusted {
  max-height: 200px;
}

.icons-trusted {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 80%;
  margin: 1rem auto;
  justify-items: center;
}

.opacid-overlay-trusted {
  width: 70%;
  height: 70%;
  margin: auto;
  background-color: #91979d24;
  border-radius: 23px;
  border: solid 1px #e5e0d6;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

@media only screen and (max-width: 768px) {
  .icons-trusted {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 0px !important;
  }

  img.icon-trusted {
    max-height: 120px !important;
  }

  .opacid-overlay-trusted {
    width: 100% !important;
    text-align: center;
  }
}

.banner.section.video-banner {
  max-width: 100vw;
  overflow: hidden;
}

img.icon-trusted:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

@media only screen and (min-width: 769px) and (max-width: 1390px) {
  img.icon-trusted {
    max-height: 150px !important;
  }

  .opacid-overlay-trusted {
    width: 90% !important;
    text-align: center;
  }
}

/* empieza proyectos reciente  */

.proyectos-container {
  padding: 2rem 0;
}

.proyectos-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem 0;
}

.proyectos-title span {
  color: #000;
}

.proyectos-list {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin: 1rem 0rem;
}

.proyecto-card {
  border: 12px solid;
  border-radius: 38px;
  width: 300px;
  text-align: left;
  position: relative;
}

.proyecto-image {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  filter: grayscale(1);
}

.proyecto-content {
  margin-top: 1rem;
}

.proyecto-content h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.proyecto-content p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.learn-more {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #d6232a;
}

.learn-more:hover {
  opacity: 0.8;
}

.learn-more span {
  margin-left: 0.5rem;
}

.main-recent {
  display: flex;
  max-width: 40rem;
}

img.dots-projects {
  max-width: 85px;
  height: fit-content;
  max-height: 21.3px;
}

.div-button-projects {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.text {
  padding-left: 1rem;
  width: 50%;
}

.title-wraper-proyectos {
  text-align: center;
  padding: 3rem;
}

span.soft-title-proyectos {
  color: darkgray;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .main-recent {
    flex-direction: column;
    align-items: center;
  }
}


.proyecto-highlight::after {
  content: '';
  position: absolute;
  top: 8px;
  right: 1px;
  width: 6.5px;
  height: 6.5px;
  background-color: red;
  border-radius: 50%;
}

.proyecto-highlight {
  position: relative;
  font-weight: bold;
  color: black;
}


/* Ocultar videos móviles en pantallas grandes */
.video-mobile {
  display: none;
}

/* Ocultar videos de escritorio en pantallas pequeñas */
@media (max-width: 768px) {
  .video-desktop {
    display: none;
  }

  .video-mobile {
    display: block;
  }
}

.gif-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 6rem 0rem;
}

.gif-wrapper img {
  max-width: 25%;
  border: solid 1.3rem #3c3432;
}

@media only screen and (max-width: 768px) {
  .gif-wrapper img {
    max-width: 35%;
    border: solid 0.5rem #3c3432 !important;
  }
}


/* Estilos generales */
.page {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}


.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.slider img {
  width: 300px;
  height: auto;
  margin: 0 20px;
}

.slider button {
  background-color: transparent;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #333;
}

.options-product {
  padding: 40px;
  background-color: #e0f7fa;
  text-align: center;
}

.options-product h3 {
  font-size: 2em;
  margin: 0px;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  margin-top: 2rem;
  font-weight: lighter;
}

.options-container {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.options-product {
  text-align: center;
  padding: 20px;
  background-color: #000000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: white;
  width: 90%;
  margin: auto;
  border-radius: 50px;
}


.options-description {
  text-align: left;
  font-style: italic;
  white-space: pre-line;
  font-size: 1.3rem;
  margin: 2rem 0rem;
  text-transform: uppercase;
}

.options-product h4 {
  font-size: 2.8em;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

/* CarouselComponent.css */

.carousel-section {
  text-align: center;
  padding: 2rem;
  background-color: #ffffff;
}

img.logo\+dots {
  height: 3.5rem;
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.carousel-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.carousel-title .highlight {
  color: #e63946;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
  overflow: hidden;
}


.carousel {
  display: flex;
  gap: 1rem;
  transition: transform 0.3s ease-in-out;
}

.carousel-item {
  min-width: 200px;
  flex-shrink: 0;
}

.carousel-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  max-height: 19rem;
}

.carousel-arrow {
  background: none;
  border: none;
  font-size: 2rem;
  color: #333;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.carousel-arrow.left {
  left: 0;
}

.carousel-arrow.right {
  right: 0;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 0.5rem;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: #e63946;
}

.product-banner {
  width: 100%;
}

.product-highlight {
  font-size: 3rem;
  color: #000;
  font-family: 'Poppins';
  font-weight: normal;
  font-style: italic;
}

.product-description {
  color: rgb(94, 30, 63);
  font-size: 1.5rem;
  text-align: right;
  margin: auto;
  display: block;
  padding-bottom: 2rem;
}

.mailerbannerproduct {
  position: relative;
  display: flex;
  flex-direction: column;
}

.mailerbannerproduct img.pc {
  inset: 0;
  width: auto;
  height: 33rem;
  object-fit: contain;
  z-index: -1;
  margin: auto 0rem;
}

.mailerbannerproduct {
  width: auto;
  height: auto;
}


.product-contact-title {
  font-size: 3rem;
  color: #000;
  margin-bottom: 1rem;
  font-family: 'Poppins-strong';
  margin: auto;
  padding-top: 2rem;
}

.product-text-wraper {
  text-align: right;
  padding: 3rem 1rem;
  ;
  max-width: 50%;
}

.rigth {
  justify-content: flex-end;
  padding: 2rem 0rem;
}

.strong {
  font-family: 'Poppins-strong';
}


/* Sección principal */
.faq-section {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 2rem 3rem;
  background: url('../public/assets/img/home-page/trusted/fondo-trusted.png');
  color: white;
  margin-top: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Título */
.faq-title h3 {
  font-size: 4rem;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
}

.faq-title span {
  font-style: italic;
  font-weight: normal;
}

/* Contenedor de preguntas */
.faq-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 40%;
}

.faq-wraper {
  display: flex;
  flex-direction: column;
  gap: 13vh;
}

.faq-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #d83f50, #385682);
  color: white;
  padding: 1.2rem 2rem;
  border-radius: 1rem;
  font-weight: bold;
  font-size: 1.1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.faq-icon {
  font-size: 2.5rem;
  color: #fff;
  opacity: 0.8;
  font-weight: 100;
  margin: 0.5rem;
}

/* Contenedor de contacto */
.faq-contact {
  text-align: left;
  margin-top: 3rem;
}



.contact-info {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}


.w-30 {
  width: 30%;
}

.faq-item p {
  font-weight: 700;
  font-size: 1.5rem;
}

p.faq-tittle {
  font-size: 2rem;
  font-weight: 700;
}

.contact-subtitle {
  font-size: 1.2rem;
}


.product-contact-button {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* Espacio entre el ícono y el texto */
  padding: 0.5rem 1rem;
  border: 2px solid #88bde3;
  /* Color del borde */
  position: relative;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  /* Color del texto */
  text-transform: uppercase;
  white-space: nowrap;
  background-color: transparent;
  /* Sin fondo */
  transform: skewX(-10deg);
  /* Bordes inclinados */
  transition: all 0.3s ease;
}

.product-contact-button:hover {
  border-color: #66a1c4;
  /* Cambio de color al pasar el mouse */
}

.product-contact-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fff;
  /* Fondo blanco del ícono */
  transform: skewX(10deg);
  /* Corrige la inclinación del ícono */
  font-size: 1.2rem;
  color: #0078aa;
  /* Color del ícono */
}

.product-contact-text {
  transform: skewX(10deg);
  /* Corrige la inclinación del texto */
}

.-product-mailer-content {
  display: flex;
  justify-content: center;
}


.options-description-single {
  width: 65%;
  font-size: 1.3rem;
  text-align: left;
  margin: 2rem 0rem;
}

.text-product {
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {

  .options-description-single {
    width: 100% !important;
    padding: 0.5rem;
  }

  .product-contact-title {
    font-size: 2rem !important;
  }

  .product-highlight {
    font-size: 2rem !important;
  }

  .product-text-wraper {
    max-width: 80% !important;
    text-align: left !important;
  }

  .product-description {
    text-align: left !important;
  }

  .carousel-title {
    font-size: 1.3rem !important;
  }

  img.logo\+dots {
    height: 1.7rem !important;
    margin-top: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .options-container {
    flex-direction: column !important;
  }

  .w-30 {
    width: 90% !important;
    margin: auto !important;
  }

  .options-container {
    gap: 10px;
  }

  .options-product {
    width: 85% !important;
    margin-bottom: 1rem;
  }

  .options-product h4 {
    font-size: 2.2em;
    margin-bottom: 0rem !important;
  }

  .faq-title h3 {
    font-size: 2rem;
    flex-direction: column;
  }

  .faq-section {
    flex-direction: column;
  }

  .img-container-carousel {
    flex: 0 0 100% !important;
    opacity: 100 !important;
  }

  .faq-wraper {
    gap: 1rem !important;
  }

  .faq-contact {
    margin-bottom: 2rem !important;
  }

  .faq-container {
    max-width: 100% !important;
  }
}



.nosotros {
  width: 100%;
  height: calc(100vh - 90px);
  /* Resta la altura del navbar (90px) */
  display: flex;
  align-items: center;
  justify-content: center;
}

.nosotros-banner {
  width: 100%;
  height: 100%;
  background-image: url('../public/assets/img/nosotros/Cielo_2.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.nosotros-banner-left {
  flex: 1;
  background-image: url('../public/assets/img/nosotros/NubeDRCH.png');
  background-repeat: no-repeat;
  background-size: cover;
  animation: nuveIzquierda 4s ease-in-out 0s 1 alternate forwards;
  z-index: 12;

}

.nosotros-banner-right {
  flex: 1;
  background-image: url('../public/assets/img/nosotros/NubeIZQ.png');
  background-repeat: no-repeat;
  background-size: cover;
  animation: nuveDerecha 4s ease-in-out 0s 1 alternate forwards;
  z-index: 11;
}

.nosotros-up {
  width: 100%;
  height: inherit;
  background-image: url('../public/assets/img/nosotros/up.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  background-position: center;
  left: 0;
  z-index: 10;
  animation: displayUp 4s ease-in-out 0s 1 alternate forwards;
}

.us-head-text {
  display: flex;
  position: absolute;
  z-index: 4;
  top: 140px;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 2.5rem;
  animation: textUp 6s ease-out 0s 1 alternate forwards;
  height: calc(100% - 140px);
}

.us-banner-tittle {
  font-family: 'Poppins-strong';
  margin: 0;
}

.us-wrapper-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.us-banner-tittle-special {
  font-family: 'cursiva';
  margin: 0;
  font-size: 6rem;
  padding-left: 1rem;
}

.up-animated {
  animation: displayUp 6s ease-out 0s 1 alternate forwards;
}

.zIndex {
  z-index: 10;
  color: #82f1e6;
}

.down-banner {
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  line-height: 0.8;
  color: white;
  padding-bottom: 1rem;
  font-size: 2rem;

}

.us-text-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.partner-main {
  background-color: rgb(201 203 203);
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: center;
}

.left-partner {
  text-align: left;
  margin: 2rem;
  flex: 1;

}

.right-partner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  flex: 1;
}

.left-partner h2 {
  font-family: 'Poppins-strong';
  font-size: 2.2rem;
}

.left-partner p {
  font-size: 1.2rem;
}

.boton-partner {
  background-color: rgb(121 196 188);
  color: black;
  font-family: 'Poppins-strong';
  border: none;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.boton-partner img {

  height: 1.5rem;
  width: auto;
  transform: rotate(-45deg);
}

.partner-icon-container {
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner-icon {
  width: 80px;
  /* Ajusta el tamaño del círculo */
  height: 80px;
  background-color: var(--circle-bg-color);
  /* Usar la variable */
  border-radius: 50%;
  /* Hace que el fondo sea un círculo */
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-icon img {
  width: 50%;
  /* Ajusta el tamaño del ícono dentro del círculo */
  height: auto;
}

.absolute {
  position: absolute;
}

.partner-wrapper {
  padding-top: 420px;
}

.relative {
  position: relative;
}

.stats-section {
  display: flex;
  width: 70%;
  margin: 2rem auto;
}

.stats-left {
  flex: 1;
}

.stats-right {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.stats-left h2 {
  font-size: 1.7rem;
  font-family: 'Poppins-strong';
  width: 80%;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.video-container-us {
  width: 80%;
}

.video-container-us video {
  width: 100%;
  border-radius: 23px;
}

.stat {
  display: flex;
  flex-direction: column;
}

.stat-number {
  font-size: 2.5rem;
  font-family: 'Poppins-strong';
}

.us-button {

  width: 25%;
  height: 11%;
  font-size: 1rem;
  background: #e9c9a9;
  border: none;
  border-radius: 26px;
  font-family: 'Poppins-strong';
}

.iso-banner {

  display: flex;
  background: #f2e5dd;
  justify-content: space-evenly;

}

.iso-banner img {
  height: 8rem;
  margin: 0.5rem;
}

.iso-banner h4 {

  font-size: 1.5rem;
  font-weight: 100;
  margin-bottom: 0;

}

.iso-banner span {

  font-family: 'Poppins-strong';
  font-size: 2rem;

}

.text-wrapper-iso {
  text-align: center;
}

/* Contenedor principal */
.proyectos-recientes {
  text-align: center;
  margin-top: 2rem;
}

/* Título */
.proyectos-titulo {
  font-size: 2rem;
  margin: 4rem;
}

/* Contenedor de las tarjetas */
.proyectos-contenedor {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 2rem;
}

/* Tarjeta */
.proyecto-tarjeta {
  width: auto;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
  height: 20rem;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.proyecto-tarjeta:hover {
  transform: scale(1.08);
  /* Aumenta el tamaño al 105% */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* Sombra más pronunciada */
}

/* Imagen de la tarjeta */
.proyecto-imagen {
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

/* Detalle de la tarjeta */
.proyecto-detalle {
  position: absolute;
  bottom: 0;
  width: 94%;
  color: #ffffff;
  padding: 10px;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  height: calc(10% + 1rem);
}

.proyecto-detalle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(191 191 191 / 50%);
  backdrop-filter: blur(4px);
  z-index: -1;
  /* Coloca el fondo detrás del texto */
  border-radius: 10px;
  /* Opcional: para redondear los bordes */
}


/* Nombre del proyecto */
.proyecto-nombre {
  font-weight: bold;
  margin-left: 1rem;
}

/* Flecha */
.proyecto-flecha {
  width: 30px;
  height: 30px;
  background-color: #7ee0d8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Icono de flecha */
.flecha-icono {
  font-size: 18px;
  transform: rotate(315deg);
}


/* Contenedor principal del slider */
.slider {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 15px;
}

/* Imagen del banner */
.slider-banner {
  width: 100%;
  height: auto;
}

.slider-image {
  width: 100%;
  display: block;
  object-fit: cover;
}

.custom-slider .slick-list {

  border-radius: 4rem;

}

.custom-slider {
  margin: 4rem;
}

/* Estilos específicos para los botones del slider con clase custom-slider */
.custom-slider .slick-next {
  right: 10px;
  /* Ajusta la posición */
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: background-color 0.3s ease;
}

.custom-slider .slick-prev {
  left: 10px;
  /* Ajusta la posición */
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: background-color 0.3s ease;
}

/* Efecto hover para los botones */
.custom-slider .slick-next:hover,
.custom-slider .slick-prev:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

/* Elimina los estilos por defecto de las flechas si es necesario */
.custom-slider .slick-next::before,
.custom-slider .slick-prev::before {
  content: '';
  /* Elimina el contenido por defecto */
}

.video-moti-inicio{

  border-radius: 3rem;
}

.video-box-inicio{
  width: 60% !important;
  margin-top: 6rem;
}

.video-mobile-inicio{
  border-radius: 1rem;
}

@media (max-width: 768px) {
  .video-box-inicio{
    width: 95% !important;
  }



  .us-banner-tittle {
    font-size: 3.5rem !important;
    text-align: center;
    z-index: 18;
  }

  .us-banner-tittle-special {
    font-size: 4.5rem !important;

  }


  .nosotros-up {
    background-image: url('../public/assets/img/nosotros/up-mobile.png') !important;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  .partner-main {
    flex-direction: column;
  }

  .partner-wrapper {
    padding-top: 15vh;
  }

  .stats-section {
    flex-direction: column;
  }
  .video-container-us {
    width: 100%;
}
.stats-left h2 {
  text-align: center;
  width: 100%;
}

  .us-button {
    width: 60%;
    margin: 1rem;
  }

  .iso-banner img {
    height: 3rem !important;
  }

  .iso-banner h4 {
    font-size: 1rem;
    margin-top: 0;
  }

  .iso-banner span {
    font-family: 'Poppins-strong';
    font-size: 1rem;
  }
  .text-wrapper-iso {
    margin: auto;
}
.proyectos-titulo {
  font-size: 1.3rem;
  margin: 2.5rem;
}
.proyectos-contenedor {
  flex-direction: column;
}
.proyecto-tarjeta {
  width: 80%;
  margin: auto;
}
.custom-slider {
  margin: 4rem 0.2rem;
}
.custom-slider .slick-list {
  border-radius: 1rem;
}
.slick-prev, .slick-next {
  width: 2rem !important;
  height: 2rem !important;
}

.politica {
  width: 73% !important;
}
.dots-calidad {
  width: 40% !important;
}


}

.politica {
  background-color: #dae4e3; 
  border-radius: 1.5rem;
  padding: 2rem; 
  margin-top: 2rem; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  width: 50%;
  margin: auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: column; /* Apilar elementos verticalmente */
  align-items: center; /* Centrar horizontalmente */
  text-align: center; /* Alinear el texto centrado */
}

/* Título */
.politica h3 {
  font-size: 1.8rem;
  color: #000;
  margin-bottom: 1rem; 
  text-align: center;
}


.politica .calidad {
  font-family: 'Poppins-strong';
  color: #000000; 
  font-style: italic;
}

/* Párrafo */
.politica p {
  font-size: 1.2rem; 
  color: #333; 
  line-height: 1.8; 
  text-align: justify;
}

.politica strong {
  font-family: 'Poppins-strong';
  color: #000; 
}

.politica .empresa {
  font-family: 'Poppins-strong';
  color: #000000; 
}

.dots-calidad{
  width: 10%;
  margin: auto;
}

.links-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.links-wrapper a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1rem;
}


































@keyframes nuveDerecha {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateX(100px);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateX(0);
  }

  55% {
    animation-timing-function: ease-out;
    transform: translateX(-15px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateX(0);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateX(0);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }
}



@keyframes nuveIzquierda {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateX(-100px);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateX(40);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateX(8px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateX(0px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateX(0);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }
}

@keyframes displayUp {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateY(350px);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateY(0);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateY(0);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateY(0);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

@keyframes textUp {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateY(-350px);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateY(0);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateY(0);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateY(0);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}